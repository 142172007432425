// Styles
// CoreUI Icons Set
// @import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
// Load font-awesome 5
@import '~@fortawesome/fontawesome-free/css/all.min.css';
// Import Main styles for this application
@import './scss/style.scss';
