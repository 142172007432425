@import "_variables";

@font-face {
  font-family: "roboto";
  src: url("../assets/font/Roboto-Regular.ttf");
}

@font-face {
  font-family: "flamante";
  src: url("../assets/font/Flamante-Roma-Medium.ttf");
}

html * {
  font-family: Roboto;
}
// Here you can add other styles
.single_passion {
  -webkit-transition: 1s;
  transition: 1s;
  background-color: red($color: #000000);
}

.container-fluid {
  margin-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.app {
  position: "relative";
  height: "auto";
  margin-bottom: "-50px";
}
.app-header {
  background-color: #2b2b2b;
  height: 65px;
  border: none;
}
.app-body {
  margin-top: 65px !important;
}
.sidebar {
  height: auto !important;
  position: absolute !important;
  bottom: 50px;
  top: 65px;
  div {
    background-color: #2b2b2b !important;
  }
  button {
    background-color: #f0f3f5 !important;
  }
}

.progress-bar-upload {
  width: 300px;
  position: fixed;
  right: 30px;
  bottom: 70px;
  padding: 10px;
  background: #c8ced3;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.17);
  opacity: 0.7;
  transition: 0.3s;
}

.progress-bar-upload:hover {
  opacity: 1;
}

.floating-retry-upload {
  position: fixed;
  right: 30px;
  bottom: 70px;
  background: #c8ced3;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.17);
  transition: 0.3s;
}

.rounded-btn-border {
  border-radius: 8px;
  padding: 5px 10px;
  margin-right: 10px;
}

.rounded-input-border {
  border-radius: 7px;
}

.background-secondary {
  background-color: $secondary;
}

.s-alert-box {
  z-index: 1021 !important;
}

.button-rounded {
  border-radius: 50px;
}

.chatbot-share-test-link-popup {
  padding: 20px;
}

.chatbot-share-test-link-copy-btn {
  margin: 10px;
  border-radius: 50px;
  color: #000000;
  border-color: #000000;
}

.chatbot-share-test-link-close-btn {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}

// country code
.react-tel-input .country-list .country-name {
  color: #000000 !important;
}

.add-contact-background {
  background-color: $tertiary;
  color: white;
}

.btn:hover {
  cursor: pointer;
}

.disable-active-link{
  a{
    background-color: transparent !important;
  }
  a:hover {
    color: #fff !important;
    background: #EF4277 !important;
  }
}

.btn-icon-roi {
  border-radius: 100px;
  color: #ffffff;
  font-weight: bold;
  padding: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  &.primary {
    border-color: $primary;
    background-color: $primary;
  }
  &.secondary {
    border-color: $secondary;
    background-color: $secondary;
  }
  &.tertiary {
    border-color: $tertiary;
    background-color: $tertiary;
  }
  &.quarternary {
    border-color: $quarternary;
    background-color: $quarternary;
  }
  &.danger {
    border-color: $danger;
    background-color: $danger;
  }
  &.outline-white {
    border-color: #ffffff;
    color: #ffffff;
  }
  img {
    border-radius: 100px;
    width: 26px;
  }
  img:hover {
    background-color: $primary;
  }
}

.btn-roi {
  color: #ffffff;
  border-radius: 8px;
  font-weight: bold;
  padding: 3px 12px;
  border: solid 2px;
  cursor: pointer;
  &.primary {
    border-color: $primary;
    background-color: $primary;
  }
  &.secondary {
    border-color: $secondary;
    background-color: $secondary;
  }
  &.tertiary {
    border-color: $tertiary;
    background-color: $tertiary;
  }
  &.quarternary {
    border-color: $quarternary;
    background-color: $quarternary;
  }
  &.lightColorRoi {
    border-color: $lightColorRoi;
    background-color: $lightColorRoi;
    color: $tertiary;
  }
  &.danger {
    border-color: $danger;
    background-color: $danger;
  }
  &.outline-white {
    border-color: #ffffff;
    color: #ffffff;
  }
}

.btn-roi-secondary-outline {
  color: $secondary;
  border: 2px solid $secondary;
  border-radius: 8px;
  font-weight: bold;
  padding: 3px 12px;
}

.btn-roi-secondary {
  color: #fff;
  border: 2px solid $secondary;
  border-radius: 20px;
  font-weight: bold;
  padding: 3px 30px 3px 30px;
}

.btn-roi-primary {
  color: #fff;
  border: 2px solid $primary;
  border-radius: 20px;
  font-weight: bold;
  padding: 3px 30px 3px 30px;
}

.contacts-detail-background {
  background-color: $tertiary;
  color: white;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #777;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* zip field without increment & decrement
Chrome, Safari, Edge, Opera */
.zip-field::-webkit-outer-spin-button,
.zip-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* zip field without increment & decrement
Firefox */
.zip-field {
  -moz-appearance: textfield;
}

.btn-link-size {
  font-size: x-small;
}

.br-20 {
  border-radius: 20px;
}

.hr-style {
  margin-top: 0 !important;
  background-color: black !important;
  &.grey {
    background-color: grey !important;
  }
  &.primary {
    background-color: $primary !important;
  }
  &.secondary {
    background-color: $secondary !important;
  }
  &.tertiary {
    background-color: $tertiary !important;
  }
  &.quarternary {
    background-color: $quarternary !important;
  }
  &.danger {
    background-color: $danger !important;
  }
}

// Sidebar & Footer
.sidebar {
  z-index: 1 !important;
}

.app-footer {
  z-index: 2 !important;
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .sidebar-minimized .sidebar {
    margin-left: 0 !important;
  }

  .sidebar-fixed .sidebar {
    width: 0;
  }

  .sidebar-minimized .main {
    margin-left: 50px !important;
  }
}

html:not([dir="rtl"]) .sidebar {
  margin-left: none !important;
}

// JointJS css
.joint-element {
  cursor: pointer !important;
}

.joint-tooltip {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 8px 8px 0 8px;
  background: white !important;
  border-radius: 20px !important;
}

.joint-popup {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 20px !important;
  background: white !important;
  border-radius: 20px !important;
  font-size: 14px;
}

.s-alert-info,
.s-alert-success,
.s-alert-warning,
.s-alert-error {
  border-radius: 20px;
}

.s-alert-success,
.s-alert-error {
  background: #ef4277;
}

/* checkbox */
input[type="checkbox"].automation-time-variable-checkbox-days,
input[type="checkbox"].column-config-checkbox-input {
  opacity: 0.0000001;
}

input[type="checkbox"].automation-time-variable-checkbox-days + label:before,
input[type="checkbox"].column-config-checkbox-input + label:before {
  border: 1px solid #333;
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0.2em 3px 0 0.95em;
  padding: 0;
  vertical-align: top;
  width: 16px;
  background-color: #ffffff;
  border: solid 1px var(--primary-color);
  position: relative;
  top: -1px;
}

input[type="checkbox"].automation-time-variable-checkbox-days:checked
  + label:before,
input[type="checkbox"].column-config-checkbox-input:checked + label:before {
  background-color: var(--primary-color);
  color: #ffffff;
  content: "\2713";
  text-align: center;
  font-weight: bold;
}

input[type="checkbox"].automation-time-variable-checkbox-days:checked
  + label:after,
input[type="checkbox"].column-config-checkbox-input:checked + label:after {
  font-weight: bold;
}

// dropdown with react-select
.custom-input-dropdown {
  border-radius: 20px !important;
}
.custom-input-dropdown__menu-list,
.custom-input-dropdown__control,
.custom-input-dropdown__menu {
  color: black;
  border: none;
  font-size: 0.875rem;
}

.custom-input-dropdown__indicator-separator {
  display: none;
}

.custom-input-dropdown__input > input {
  width: 0;
  height: 0;
  color: transparent;
}

input[type="checkbox"].custom-checkbox-pull-left + label:before{
  margin: 1px 5px 0 0px;
  background-color: #222 !important;
  border: none;
}

.header-shadow{
  box-shadow: 0 3px 38px rgba(0,0,0, 0.15), 0 2px 5px rgba(0,0,0,0.11);
  z-index: 999;
}

.ag-body-horizontal-scroll-viewport{
  overflow-x: hidden !important;
}

// css for override CreatableSelect component style
.css-1pahdxg-control:hover, .css-1pahdxg-control{
  border-color: #ef4277 !important;
  box-shadow: 0 0 0 1px #ef4277 !important;
}

// should extract to spacing/layout scss, based on a style guide
.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.m-0{
  margin: 0 !important;
}

.pl-1{
  padding-left: 6px;
}

.pl-2{
  padding-left: 12px;
}

.py-2{
  padding-left: 12px important;
  padding-right: 12px important;
}

.pt-2{
  padding-top: 12px !important;
}

.pt-9px{
  padding-top: 9px !important;
}


@media screen and (max-width: 768px) {
  .mb-6-md{
    margin-bottom: 6px !important;
  }
}

.border-radius-normal{
  border-radius: 6px !important;
}

.flex-container{
  display: flex;
  justify-content: center;
}

.flex-container-right{
  display: flex;
  justify-content: right;
}



// applied only to drop down on campaign builder page save as button
.custom-dropdown-width {
  min-width: 100px !important;
}

.z-index-1020 {
  z-index: 1020;
}