// Variable overrides
$primary: #EF4277;
$secondary: #3B3A3A;
$danger: #FF0000;
$tertiary: #414141;
$quarternary: #2C2C2C;
$contactTimeline: #DEDDDD;
$lightColorRoi: #e4e5e6;
$lightPrimaryRoi: #F5F5F5;
$darkPrimaryRoi: #E5E5E5;
$fontHeaderGridRoi: #818181;
$bgPrimaryRoi: #343434;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger,
  "tertiary": $tertiary,
  "quarternary": $quarternary,
  "contactTimeline": $contactTimeline,
  "lightColorRoi": $lightColorRoi,
  "lightPrimaryRoi": $lightPrimaryRoi,
  "darkPrimaryRoi": $darkPrimaryRoi,
  "fontHeaderGridRoi": $fontHeaderGridRoi,
  "bgPrimaryRoi": $bgPrimaryRoi
);
