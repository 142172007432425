@import "scss/_variables";

.tox-tinymce, .tox {
  z-index: 0 !important;
}

.automation-input-disabled {
  color: gray !important;
  background-color: $secondary !important;
}

/* CSS for react-phone-input-2 */

.phoneinput-input-class {
  padding-left: 110px !important;
  border-radius: 20px !important;
  background-color: #222 !important;
  color: var(--white) !important;
  border: var(--quarternary-color) !important;
  font-weight: bold !important;
}

.phoneinput-button-class {
  background-color: #222 !important;
  color: var(--white) !important;
  border: 2px solid #222 !important;
  border-radius: 20px !important;
  font-weight: bold;
  padding: 3px 30px 3px 30px !important;
}

.phoneinput-button-class .arrow {
  border-top: 4px solid var(--white) !important;
}

.phoneinput-button-class .selected-flag:hover, .phoneinput-button-class .selected-flag.open {
  background-color: #222 !important;
}

.phoneinput-button-class .country-list {
  margin-left: -30px !important;
}

.merge-tags-dropdownmenu {
  transform: none !important;
  top: 31px !important;
  max-height: 420px;
  overflow-y: scroll;
  width: 320px;
  background-color: #222222;
  border: 0;
}

.survey-tags-dropdownmenu {
  transform: none !important;
  top: 31px !important;
  max-height: 420px;
  overflow-y: scroll;
  width: 250px;
  background-color: #222222;
  border: 0;
}

.merge-tags-search-input {
  margin: 10px auto;
  width: 90%;
  padding-left: 30px;
  border-radius: 0.5rem;
}

.merge-tags-search-input-container {
  position: relative;
}

.fa-search {
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  color: #5c6873
}

.merge-tags-header-dropdownitem {
  font-weight: lighter;
  color: #5c6873;
  background-color: #222222;
  border: 0;
  font-size: 12px;
}

.merge-tags-childe-dropdownitem {
  color: white;
  border: 0;
  font-size: 16px;
  font-weight: lighter;
}

.merge-tags-childe-dropdownitem:hover {
  background-color: #ef4277;
  color: white;
  border: 0;
}

.merge-tags-bottom-line {
  border-bottom: 1px white solid;
}

.merge-tags-dropdown {
  float: right;
  margin-right: 0px !important;
}

.automation-sidebar-form-switch .switch-specific-time{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 8px;
  color: white;
  padding-right: 2px;
}