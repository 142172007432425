.bg-white{
    background-color: #ffffff !important;
}

.bg-transparent{
    background-color: transparent !important;
}

.bg-black-not-hover:not(:hover){
    background-color: #000000 !important;
}